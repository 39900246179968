import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Section7 = () => {
  return (
    <section className='section7 cs-p'>
      <Container fluid="lg">
        <Link to={'/plans'} className='text-decoration-none'>
          <Row className='align-items-center g-md-5 g-1'>
            <Col md={3} className="text-center mb-md-0 mb-3">
              <img loading="lazy" src="/images/section7-1.svg" alt="1CDN" className='img-fluid' width={109} height={135} title='1CDN - plans' />
            </Col>
            <Col md={5} className='mb-md-0 mb-3'>
              <h6 className="coloredTitle text-white fs-1">
                Our<span> FREE and PREMIUM plan</span>
              </h6>
              <p className="mb-0">
                FREE or PREMIUM, choose your plan to help you grow.
              </p>
            </Col>
            <Col md={4} className="text-center">
              <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/cdn_logo_white.svg" alt="1CDN" className='img-fluid w-50' title='1CDN - Logo' width={151} height={150} />
            </Col>
          </Row>
        </Link>
      </Container>
    </section>
  )
}

export default Section7