import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const ServiceExpiry = ({ entity_identification, trial_left_days }: { entity_identification: string, trial_left_days: number }) => {
  const [close, setClose] = useState(false);

  const handleCLoseAlert = () => {
    setClose(true)
  }

  if (close)
    return null

  return (
    <div className='notif-box'>
      <Container fluid className='p-0'>
        <Row className='align-items-center'>
          <Col xs={'auto'}>
            <div className="label">
              <img src="https://cdn.1cdn.app/application/UMD/23030414020089_trial.svg" alt="1CDN trial expiry" className='img-fluid me-2' />
              <b>Expiry time</b>
            </div>
          </Col>
          <Col>
            <div className="detail">
              <p className="mb-0">
                The free trial will expire in {trial_left_days} days, and then you will be charged automatically. You can cancel it if you don't want to use it.
              </p>
              <i className='bi bi-x cursor-pointer ms-auto' onClick={handleCLoseAlert}></i>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ServiceExpiry