import { Spinner } from "react-bootstrap"

const LoadingPage = () => {
  return (
    <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <img src="https://cdn.1cdn.app/application/CDN/cdn_logo_fill_2.svg" alt="1CDN" width={150} height={150} title={"1CDN"} className="img-fluid mb-3" />
      <span>
        Loading...
        <Spinner animation="border" size='sm' className="ms-2" />
      </span>
    </div>
  )
}

export default LoadingPage