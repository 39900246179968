import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useMutation, useQueryClient } from "react-query";
import HttpService from "../../services/http";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { cdnPlans } from "../Plans";
import PlansModalBody from "../../components/PlansModalBody";
import { Timer } from "./ForgotPassword";
import BsInputPassword from "../../components/public/BsInputPassword";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";

const schema = Joi.object({
  user_email: Joi.string()
    .trim()
    .required()
    .regex(/^[^\s@`!@#$%^&*()=]+@[^\s@`!@#$%^&*()=]+\.[^\s@`!@#$%^&*()=]+$/)
    .label("Email")
    .messages({
      "string.pattern.base": "Must be a valid email address",
    }),
  user_password: Joi.string().required().label("Password"),
  // user_confirm_password: Joi.any().valid(Joi.ref("user_password")).required().messages({ "any.only": "Those passwords didn't match. Try again." }).label("Confirm password"),
  user_agree_terms: Joi.boolean()
    .invalid(false)
    .required()
    .label("Terms and conditions"),
});

const Register = () => {
  const localStorage = useAppLocalStorage();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let location: any = useLocation();
  const [searchParams] = useSearchParams();

  let from = location.state?.from?.pathname || "/control-panel";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user) navigate(from);

    reset();

    let planInPath = searchParams.get("plan");

    if (
      cleaner &&
      planInPath &&
      cdnPlans.map((plan) => plan.plan_code).includes(planInPath as string)
    )
      localStorage.setItem("_cdn_service_client_plan", planInPath);

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate, searchParams, localStorage, reset]);

  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [apiErrors, setApiErrors] = useState<any>({});
  const [paymentStatus, setPaymentStatus] = useState<"idle" | "loading">(
    "idle"
  );

  // Regex Pass
  // const [isPasswordPatternValid, setIsPasswordPatternValid] = useState(false)
  // const [startValidating, setStartValidating] = useState(false)

  // const PreferencesQuery = useQuery('app-preferences', () => HttpService.get('/auth/app-preferences'));

  const RegisterUserMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) => HttpService.post("/auth/register", data),
    {
      onMutate: () => {
        setApiErrors({});
      },
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);
      },
    }
  );

  const ResendEmailVerification = useMutation<any, AxiosError<any, any>>(
    () =>
      HttpService.post(`/auth/${userData.userIdentification}/validation-link`),
    {
      onSuccess: () => {
        toast.success(
          "The verification link has been sent to your email address."
        );
        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);
        setShowTimer(false);
      },
    }
  );

  const PasswordCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    { password: string; level?: string | null }
  >((data) => HttpService.post("/auth/check-password", data), {
    onError: (err) => {
      toast.error(err.response?.data.message || err.message);

      setApiErrors({ ...apiErrors, user_password: err.response?.data.message });
      setError("user_password", err.response?.data.message || err.message);
    },
  });

  const EmailVerificationCheckMutation = useMutation<
    any,
    AxiosError<any, any>,
    string
  >(
    (customer_identification) =>
      HttpService.get(`${customer_identification}/profile/status`),
    {
      onError: (err) => {
        toast.error(err.response?.data.message || err.message);
      },
    }
  );

  // const handleCheckRegex = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (PreferencesQuery.isSuccess) {
  //     let value = e.target.value;
  //     let regex = new RegExp(PreferencesQuery.data.data.element.password_validation_regex_level.regex);

  //     if (value === '' || value === undefined)
  //       setStartValidating(false)
  //     else
  //       setStartValidating(true)

  //     if (regex.test(e.target.value))
  //       setIsPasswordPatternValid(true)
  //     else
  //       setIsPasswordPatternValid(false)
  //   }
  // }

  const handleLogin = () => {
    let data: any = {
      user_identification: userData.userIdentification,
      user_email: userData.user_email,
      user_password: userData.user_password,
    };

    EmailVerificationCheckMutation.mutate(data.user_identification, {
      onSuccess: (res) => {
        if (res.data.element.user_is_active) {
          setPaymentStatus("loading");

          queryClient
            .fetchQuery("payment-status", () =>
              HttpService.get(
                `/auth/${data.user_identification}/verify-payment`
              )
            )
            .then((value) => {
              if (value.data.element.plans.length > 0) {
                setPaymentStatus("idle");

                auth.login({
                  ...res.data.element,
                  user_email: data.user_email,
                  accessToken: value.data.element.accessToken,
                  rememberMe: false,
                });

                navigate(from, { replace: true });
              } else {
                setShowPlansModal(true);
              }
            })
            .catch((err: any) => {
              setPaymentStatus("idle");

              if (err.response?.status === 402) {
                setShowConfirmEmailModal(false);
                setShowPlansModal(true);
              } else toast.error(err.response?.data.message || err.message);
            });
        } else {
          setPaymentStatus("idle");

          toast.error("Email address is not verified");
        }
      },
      onError: (err) => {
        setPaymentStatus("idle");
      },
    });
  };

  const onSubmit = (data: any) => {
    PasswordCheckMutation.mutate(
      {
        password: data.user_password,
        level: localStorage.getItem("_cdn_password_level"),
      },
      {
        onSuccess: () => {
          RegisterUserMutation.mutate(data, {
            onSuccess: (res) => {
              setShowConfirmEmailModal(true);
              setUserData({
                ...res.data.element,
                user_email: data.user_email,
                user_password: data.user_password,
              });

              localStorage.setItem(
                "_cdn_credential",
                JSON.stringify({
                  user_identification: res.data.element.userIdentification,
                  user_email: data.user_email,
                  user_password: data.user_password,
                  is_new_user: true,
                })
              );
            },
          });
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>1CDN - Register</title>
        <meta name="description" content="1CDN - Register" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/register`}
        />
      </Helmet>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="fs-4 fw-bold mb-0">Create an account</h1>
        <small>
          Already have an account?&nbsp;
          <Link to="/login" className="text-black">
            Sign in
          </Link>
        </small>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="user_email">Email</Form.Label>
          <Form.Control
            id="user_email"
            type="email"
            isInvalid={!!errors.user_email}
            {...register("user_email")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.user_email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <BsInputPassword
          label="Password"
          isInvalid={!!errors.user_password || !!apiErrors.user_password}
          errorMessage={
            errors.user_password?.message || apiErrors.user_password
          }
          className={"mb-3"}
          rhfControls={register("user_password")}
        />

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="user_agree_terms"
            label={
              <small>
                By creating an account, I agree to our{" "}
                <a href="/tos" target="_blank" className="main-color">
                  Terms of Service and Privacy Policy
                </a>
              </small>
            }
            isInvalid={!!errors.user_agree_terms}
            {...register("user_agree_terms")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.user_agree_terms?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="w-100 rounded-pill auth-btn"
          size="lg"
          disabled={RegisterUserMutation.isLoading}
        >
          {RegisterUserMutation.isLoading ? (
            <Spinner animation="grow" />
          ) : (
            "Create an account"
          )}
        </Button>
      </Form>

      {/* Confirm Email Modal */}
      <Modal
        show={showConfirmEmailModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body className="text-center">
          <h2 className="fw-bold mb-2" style={{ color: "#0C271B" }}>
            Check your inbox
          </h2>
          <p className="fs-5" style={{ color: "#3D5249" }}>
            You will need to verify your email to complete registration
          </p>
          <img
            loading="lazy"
            src="https://cdn.1cdn.app/application/CDN/cdn_verify_your_email.svg"
            alt="1CDN"
            className="img-fluid my-4"
            width={300}
          />
          <p style={{ color: "#0C271B" }}>
            An email has been sent to{" "}
            {userData?.user_email && <b> {userData.user_email} </b>} with a link
            to verify your account, if you have not received the email after a
            few minutes, please check your spam folder
          </p>
          <Row className="g-3">
            <Col sm={6} className="text-sm-end">
              <Button
                variant="success"
                onClick={handleLogin}
                disabled={
                  EmailVerificationCheckMutation.isLoading ||
                  paymentStatus === "loading"
                }
              >
                {EmailVerificationCheckMutation.isLoading ||
                paymentStatus === "loading" ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "I confirmed my email"
                )}
              </Button>
            </Col>
            <Col sm={6} className="text-sm-start">
              <Button
                variant="outline-success"
                onClick={() =>
                  userData.user_email &&
                  ResendEmailVerification.mutate(undefined)
                }
                disabled={showTimer}
              >
                {ResendEmailVerification.isLoading ? (
                  <Spinner animation="grow" size="sm" />
                ) : showTimer ? (
                  <span className="text-muted">
                    <Timer setShowTimer={setShowTimer} ttl={120} /> left
                  </span>
                ) : (
                  "Resend email"
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showPlansModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>Choose your plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlansModalBody
            user_identification={userData?.userIdentification as string}
            chosenPlan={searchParams.get("plan") as string}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
