import { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import useAppLocalStorage from '../../../hooks/useAppLocalStorage';

const IntroPopUp = () => {
  const localStorage = useAppLocalStorage()
  const [show, setShow] = useState(false);

  useEffect(() => {
    let hasAlreadyConfirm = localStorage.getItem('_cdn_intro');

    if (!hasAlreadyConfirm) {
      setTimeout(() => {
        setShow(true);
      }, 5000);
    }
  }, [localStorage])

  const handleConfirm = () => {
    localStorage.setItem("_cdn_intro", String(new Date().getTime()));
    setShow(false);
  }

  if (show)
    return (
      <Modal show={show} size='lg' centered onHide={handleConfirm}>
        <Modal.Body>
          <div className="closeBtnArea mb-2 d-flex justify-content-end">
            <Button variant='light'
              className='rounded-circle d-flex align-items-center justify-content-center p-0'
              style={{ width: 30, height: 30 }} onClick={handleConfirm}>
              <i className='bi bi-x'></i>
            </Button>
          </div>
          <Container>
            <Row className='px-lg-4 g-4'>
              <Col md={{ order: 1, span: 6 }} xs={{ order: 2, span: 12 }}>
                <div className="h-100 d-flex flex-column">
                  <div>
                    <h2><span className='main-color'>1CDN,</span> Faster and Easy!</h2>
                    {/* <h5 className="fw-light my-3" style={{ color: "#524759" }}>
                      We are committed to providing a secure platform for you to manage your users and give you the free mind to focus on your real business.
                    </h5> */}
                  </div>
                  <div className="mt-auto">
                    <p className="small text-justify">
                      <span
                        className="text-black-50">1CDN just use strictly necessary cookies</span> 🍪 <span
                          className="text-black-50">to improve your experience on this site.</span>
                    </p>
                    <Button variant='success' className='rounded-3 text-white'
                      onClick={handleConfirm}>
                      ok
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={{ order: 2, span: 6 }} xs={{ order: 1, span: 12 }} className="d-flex justify-content-center">
                <img
                  src="https://cdn.1cdn.app/application/CDN/22111410322596_1cdngif_copy.gif"
                  className='img-fluid' alt="1CDN - cookie"
                  loading='lazy'
                  height={300}
                  width={300}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    )
  else
    return null
}

export default IntroPopUp