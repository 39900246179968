import { Col, Container, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"

const HomeLayoutFooter = () => {
  const location = useLocation();

  return (
    <footer className="home_layout_footer">
      <Container fluid={'lg'}>
        <Row className="g-md-3 g-4">
          <Col lg={7} md={5}>
            <Link to={'/'} className="brand" onClick={() => window.scrollTo(0, 0)}>
              <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/cdn_logo_white.svg" alt="1CDN" title='1CDN - Logo' className="img-fluid" width={151} height={150} style={{ transform: `rotate(90deg)` }} />
              <span className="main-color">1</span>CDN
            </Link>
          </Col>
          <Col lg={3} md={4}>
            <div className="menus">
              <p>Contact</p>
              <ul className="list-unstyled mb-0 p-0">
                <li>
                  <a href={'mailto:info@1cdn.app'}>
                    info@1cdn.app
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={2} md={3}>
            <div className="menus">
              <p>Pages</p>
              <ul className="list-unstyled mb-0 p-0">
                <li>
                  {location.pathname === '/' ?
                    <Link to={'/'} onClick={() => window.scrollTo(0, 0)}>
                      Home
                    </Link>
                    :
                    <Link to={'/'}>
                      Home
                    </Link>
                  }
                </li>
                <li>
                  {location.pathname === '/plans' ?
                    <Link to={'/plans'} onClick={() => window.scrollTo(0, 0)}>
                      Plan
                    </Link>
                    :
                    <Link to={'/plans'}>
                      Plan
                    </Link>
                  }
                </li>
                <li>
                  {location.pathname === '/contact' ?
                    <Link to={'/contact'} onClick={() => window.scrollTo(0, 0)}>
                      Contact
                    </Link>
                    :
                    <Link to={'/contact'}>
                      Contact
                    </Link>
                  }
                </li>
                <li>
                  <Link to={'/tos'}>
                    Policy
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="extra-footer text-center mt-5">
          <p className="mb-0 small">
            © {new Date().getFullYear()} - 1CDN - Made with ❤️ by passionate teams - version: {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </Container>
    </footer >
  )
}

export default HomeLayoutFooter