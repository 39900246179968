import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Section1 = () => {
  return (
    <section className='section1 cs-p' id='top'>
      <Container fluid="lg">
        <Row>
          <Col lg={6}>
            <h1>
              A <span>C</span>ontent <span>D</span>elivery <span>N</span>etwork provider
            </h1>

            <p>
              We are here to bring content as close to your users as possible.
            </p>

            <Link to={'/register'} className='btn btn-success'>
              Create your account for free
            </Link>

            <ul className='list-unstyled mt-3 p-0'>
              <li>
                <i className='bi bi-check fs-4 text-success me-2'></i>
                <span>Supporting all major formats</span>
              </li>
              <li>
                <i className='bi bi-check fs-4 text-success me-2'></i>
                <span>Files management</span>
              </li>
              <li>
                <i className='bi bi-check fs-4 text-success me-2'></i>
                <span>1000+ files</span>
              </li>
              <li>
                <i className='bi bi-check fs-4 text-success me-2'></i>
                <span>Custom dashboard</span>
              </li>
            </ul>
          </Col>
          <Col className='d-md-block d-none'>
            <div className="text-center">
              <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/cdn_deliver_fast.svg" alt="1CDN" className='img-fluid' width={300} height={150} title='1CDN deliver content very fast' />
            </div>
          </Col>
        </Row>
      </Container>
      <a href={'#intro'} className="guide">
        <img loading="lazy" src="/images/guide.svg" alt="1CDN" width={30} height={86} title='1CDN next section' />
      </a>
    </section>
  )
}

export default Section1