import React, { useState } from 'react'
import { Button, Container, Offcanvas } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';
import { useWindowSize } from '../../../hooks/useWindowSize';

const HomeLayoutNavbar = ({ showMenu = true }: { showMenu?: boolean }) => {
  const auth = useAuth();

  const location = useLocation();

  const [width] = useWindowSize();

  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showTabletMenu, setShowTabletMenu] = useState(false);

  const handleDecideResponsiveMenu = () => {
    if (width >= 767.98 && width <= 992)
      setShowTabletMenu(true)
    else if (width >= 576 && width < 767.98)
      setShowOffCanvas((true))
    else
      setShowOffCanvas(true)
  }

  const checkIfIsAuthPage = () => {
    if (location.pathname.includes('/login') || location.pathname.includes('/register') || location.pathname.includes('/reset-password') || location.pathname.includes('/forgot-password'))
      return true
    else return false
  }

  return (
    <nav className={`home_layout_nav ${checkIfIsAuthPage() ? 'isAuthPage' : ''}`}>
      <Container fluid={'lg'}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="brand">
            <Link to={'/'} onClick={() => window.scrollTo(0, 0)}>
              <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/cdn_logo_fill_2.svg" alt="1CDN" className='img-fluid d-md-inline-flex d-none' width={149} height={150} title='1CDN logo' />
              <span className="main-color">1</span>CDN
            </Link>
            <p className='d-sm-block d-none'>
              <b>The</b> Content Delivery Network provider
            </p>
          </div>
          {showMenu && (
            <div className="actions ms-auto position-relative">
              {auth.user ? (
                <Link to={'/control-panel'} className='btn-auth btn btn-lg rounded-pill d-lg-block d-none'>
                  Dashboard
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7902 1.00002C17.6039 1.18738 17.4994 1.44084 17.4994 1.70502C17.4994 1.96921 17.6039 2.22266 17.7902 2.41002L21.4202 6.00002L1.00017 6.00002C0.734955 6.00002 0.480599 6.10538 0.293062 6.29291C0.105526 6.48045 0.000169826 6.7348 0.000169829 7.00002C0.000169832 7.26524 0.105526 7.51959 0.293062 7.70713C0.480599 7.89466 0.734955 8.00002 1.00017 8.00002L21.4102 8.00002L17.7902 11.62C17.6965 11.713 17.6221 11.8236 17.5713 11.9454C17.5205 12.0673 17.4944 12.198 17.4944 12.33C17.4944 12.462 17.5205 12.5927 17.5713 12.7146C17.6221 12.8365 17.6965 12.9471 17.7902 13.04C17.9776 13.2263 18.231 13.3308 18.4952 13.3308C18.7594 13.3308 19.0128 13.2263 19.2002 13.04L23.1202 9.13002C23.682 8.56752 23.9976 7.80502 23.9976 7.01002C23.9976 6.21502 23.682 5.45252 23.1202 4.89002L19.2002 1.00002C19.0128 0.81377 18.7594 0.709229 18.4952 0.709229C18.231 0.709229 17.9776 0.81377 17.7902 1.00002Z" fill="white" />
                  </svg>
                </Link>
              ) : !checkIfIsAuthPage() && (
                <div className='d-inline-flex align-items-center d-lg-block d-none'>
                  <Link to={'/login'} className='btn btn-outline-success rounded-pill me-2'>
                    Login
                  </Link>
                  <Link to={'/register'} className='btn btn-success rounded-pill'>
                    Get started for free
                  </Link>
                </div>
              )}
              <div className="d-flex">
                {!checkIfIsAuthPage() && (
                  <Link to={'/register'} className='btn btn-success rounded-pill me-2 d-lg-none d-block'>
                    Get started for free
                  </Link>
                )}
                {showTabletMenu ? <Button variant='link' onClick={() => setShowTabletMenu(false)} className="p-0">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 3L3 21" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 3L21 21" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Button> : <Button variant='link' className='d-lg-none d-block p-0' onClick={handleDecideResponsiveMenu}>
                  <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="27.4695" y1="2.5" x2="3.46948" y2="2.5" stroke="#57CC99" strokeWidth="5" strokeLinecap="round" />
                    <line x1="27.4695" y1="22.5" x2="3.46948" y2="22.5" stroke="#57CC99" strokeWidth="5" strokeLinecap="round" />
                    <line x1="27.5" y1="12.5" x2="3.5" y2="12.5" stroke="#57CC99" strokeWidth="5" strokeLinecap="round" />
                  </svg>
                </Button>}
              </div>
              {showTabletMenu && (
                <div className='tablet-menu position-absolute'>
                  <ul className='mb-4'>
                    <li>
                      <Link to={'/'} onClick={() => setShowTabletMenu(false)}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to={'/plans'} onClick={() => setShowTabletMenu(false)}>
                        Plan
                      </Link>
                    </li>
                    <li>
                      <Link to={'/contact'} onClick={() => setShowTabletMenu(false)}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                  {auth.user ? (
                    <ul>
                      <li className='mb-2'>
                        <Link to={'/control-panel'} className="btn w-100 rounded-pill btn-dark" onClick={() => setShowTabletMenu(false)}>
                          Dashboard
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li className='mb-2'>
                        <Link to={'/register'} className="btn w-100 rounded-pill btn-dark" onClick={() => setShowTabletMenu(false)}>
                          Get started for free
                        </Link>
                      </li>
                      <li>
                        <Link to={'/login'} className="btn w-100 rounded-pill btn-outline-dark" onClick={() => setShowTabletMenu(false)}>
                          Log in
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              )}
              <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)} placement={'end'} className='cdn-menu-mobile'>
                <Offcanvas.Body className="p-0">
                  <div className="title d-flex align-items-center bg-white px-3 py-3">
                    <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/cdn_logo_fill_2.svg" alt="1CDN" className='img-fluid me-3' width={50} />
                    <h1 className='fw-bold'>1CDN</h1>
                    <svg className='ms-auto' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setShowOffCanvas(false)}>
                      <path d="M21 3L3 21" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3 3L21 21" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="d-flex flex-column bodyContent">
                    <ul className="list-unstyled navItems my-auto">
                      <li className='mb-4'>
                        <Link className='text-dark text-decoration-none' to={'/'} onClick={() => setShowOffCanvas(false)}>
                          Home
                        </Link>
                      </li>
                      <li className='mb-4'>
                        <Link className='text-dark text-decoration-none' to={'/plans'} onClick={() => setShowOffCanvas(false)}>
                          Plan
                        </Link>
                      </li>
                      <li className='mb-4'>
                        <Link className='text-dark text-decoration-none' to={'/contact'} onClick={() => setShowOffCanvas(false)}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                    {auth.user ? (
                      <ul className="list-unstyled p-0 mb-0">
                        <li className='mb-2'>
                          <Link to={'/control-panel'} className="btn btn-lg w-100 rounded-pill btn-dark" onClick={() => setShowOffCanvas(false)}>
                            Dashboard
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className="list-unstyled p-0 mb-0">
                        <li className='mb-2'>
                          <Link to={'/register'} className="btn btn-lg w-100 rounded-pill btn-dark" onClick={() => setShowOffCanvas(false)}>
                            Get started for free
                          </Link>
                        </li>
                        <li>
                          <Link to={'/login'} className="btn btn-lg w-100 rounded-pill btn-outline-dark" onClick={() => setShowOffCanvas(false)}>
                            Log in
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          )}
        </div>
      </Container>
    </nav >
  )
}

export default HomeLayoutNavbar