import React from 'react'

const Maintenance = () => {
  return (
    <>
      <h1 className="audiowide">
        503!
      </h1>
      <h4 className="prompt fw-bold">
        We are updating our services, please wait a little, then refresh your browser.
      </h4>
      <img loading="lazy" src="https://cdn.1cdn.app/application/CDN/22102206243832_503_error.svg" alt="1CDN" className="img-fluid my-5 d-block mx-auto" style={{width:"auto"}} />
    </>
  )
}

export default Maintenance