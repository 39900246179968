/* eslint-disable array-callback-return */
import {
  AxiosError,
  // AxiosResponse
} from "axios";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { renderFileThumbnail } from "./LatestUploadedFiles";

export const handleCopyCdnPath = (cdnPath: string) => {
  window.navigator.clipboard.writeText(cdnPath);

  toast.success("1CDN destination copied");
};

type FileType = {
  cdnPath: string;
  extension: string;
  file: string;
  size_byte: number;
  uploadedDate: string;
  selected?: boolean;
};

type StateType = {
  MasterChecked: boolean;
  SelectedList: string[];
  List: FileType[];
};

const defaultFiltersState = {
  from: "1",
  number: "20",
  extension: "all",
  sortby: "date-desc",
};

const FilesList = () => {
  const { customer_identification } = useAuth();

  const [paginationData, setPaginationData] = useState<{
    next_page_token: string | null;
    total_files: number;
  }>();
  const [filterQueries, setFilterQueries] = useState(defaultFiltersState);
  // const [fileFormats, setFileFormats] = useState<string[]>([]);
  const [state, setState] = useState<StateType>({
    MasterChecked: false,
    SelectedList: [],
    List: [],
  });

  const queryClient = useQueryClient();

  useQuery(
    "acceptable-format",
    () => HttpService.get("/ui/acceptable-format")
    // {
    //   onSuccess: (data: AxiosResponse<any, any>) => {
    //     setFileFormats([
    //       "all",
    //       ...data.data.element.map((item: string) => item.slice(1)),
    //     ]);
    //   },
    // }
  );

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    ["files", filterQueries],
    () =>
      HttpService.get(
        // `/${customer_identification}/cdn?from=${filterQueries.from}&number=${
        //   parseInt(filterQueries.number) + 1
        // }&extension=${filterQueries.extension}&sortby=${filterQueries.sortby}`,
        `/${customer_identification}/cdn?&number=${parseInt(
          filterQueries.number
        )}${
          paginationData?.next_page_token
            ? `&next_page_token=${paginationData?.next_page_token}`
            : ""
        }`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSuccess({ data }) {
        if (data.element) {
          setPaginationData(data.element.pagination);
          setState({ ...state, List: data.element.files });
        }
      },
    }
  );

  // const DeleteAllFilesMutation = useMutation<any, AxiosError<any, any>>(
  //   () => HttpService.delete(`/${customer_identification}/cdn/delete-all`, {
  //     auth: HttpService.getToken(),
  //   })
  // )

  const DeleteSomeFilesMutation = useMutation<
    any,
    AxiosError<any, any>,
    string[]
  >(
    (files) =>
      HttpService.delete(`/${customer_identification}/cdn/delete-some`, {
        data: {
          files,
        },
        auth: HttpService.getToken(),
      }),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onMasterCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempList = state.List;

    tempList.map((file: FileType) => (file.selected = e.target.checked));

    setState({
      MasterChecked: e.target.checked,
      List: tempList,
      SelectedList: state.List.filter((e: any) => e.selected).map(
        (file: FileType) => file.file
      ),
    });
  };

  const onItemCheck = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    let tempList = state.List;
    tempList.map((file: FileType) => {
      if (file.file === item.file) {
        file.selected = e.target.checked;
      }
      return file;
    });

    const totalItems = state.List.length;
    const totalCheckedItems = tempList.filter(
      (e: FileType) => e.selected
    ).length;

    setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      SelectedList: state.List.filter((e: FileType) => e.selected).map(
        (file: FileType) => file.file
      ),
    });
  };

  // const getSelectedRows = () => {
  //   setState({
  //     ...state,
  //     SelectedList: state.List.filter((e: FileType) => e.selected).map((file: FileType) => file.file),
  //   });
  // }

  // const handleDeleteAllFIles = () => {
  //     const swalWithBootstrapButtons = Swal.mixin({
  //         customClass: {
  //             confirmButton: 'btn btn-success',
  //             cancelButton: 'btn btn-danger'
  //         },
  //     })

  //     swalWithBootstrapButtons.fire({
  //         title: 'Are you sure?',
  //         text: "You won't be able to revert this!",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonText: 'Yes, delete all!',
  //         cancelButtonText: 'No, cancel!',
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             DeleteAllFilesMutation.mutate(undefined, {
  //                 onSuccess: () => {
  //                     queryClient.invalidateQueries('files');
  //                     queryClient.invalidateQueries('total-files');

  //                     swalWithBootstrapButtons.fire(
  //                         'Deleted!',
  //                         'Your file has been deleted.',
  //                         'success'
  //                     )
  //                 },
  //                 onError: (err) => {
  //                     toast.error(err.message)
  //                 }
  //             })

  //         } else if (
  //             /* Read more about handling dismissals below */
  //             result.dismiss === Swal.DismissReason.cancel
  //         ) {
  //             swalWithBootstrapButtons.fire(
  //                 'Cancelled',
  //                 'Your files are safe :)',
  //                 'error'
  //             )
  //         }
  //     })
  // }

  const handleDeleteSelectedRows = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteSomeFilesMutation.mutate(state.SelectedList, {
            onSuccess: () => {
              setFilterQueries(defaultFiltersState);

              setState({
                MasterChecked: false,
                SelectedList: [],
                List: [],
              });

              queryClient.invalidateQueries("files");
              queryClient.invalidateQueries("total-files");

              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            },
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your files are safe :)",
            "error"
          );
        }
      });
  };

  const handleChangeOffsetPagination = (offset: number) => {
    setState({
      ...state,
      MasterChecked: false,
      SelectedList: [],
    });

    setFilterQueries({ ...filterQueries, from: offset.toString() });
  };

  const renderFileName = (name: any) => {
    const [, ...rest] = decodeURI(name).split("_");

    let text = rest.join("_");

    if (text.length > 20)
      return (
        <span title={decodeURI(name)}>
          {text.substring(0, 10) + "...." + name.substr(-7)}
        </span>
      );
    else return <span>{text}</span>;
  };

  const handleFilters = (changes: any) => {
    return setFilterQueries({ ...changes });
  };

  let content;

  if (isLoading)
    content = (
      <tr>
        <td colSpan={8} align={"center"}>
          <Spinner animation="grow" size="sm" />
        </td>
      </tr>
    );

  if (isError)
    content = (
      <tr>
        <td colSpan={8} align={"center"}>
          <Button variant="link" onClick={() => refetch()}>
            Reload
          </Button>
        </td>
      </tr>
    );

  if (isSuccess)
    content = (
      <>
        {state.List?.length > 0 ? (
          state.List?.map((file: FileType, index: number) => {
            if (index < parseInt(filterQueries.number))
              return (
                <tr key={file.file} style={{ verticalAlign: "middle" }}>
                  <td className="ps-2">
                    {parseInt(filterQueries.from) + index}
                  </td>
                  <td>
                    {/* <input type="checkbox" name={file.file} checked={checkedItems[file.file]} onChange={handleSelectRow} /> */}
                    <input
                      type="checkbox"
                      checked={file.selected}
                      className="form-check-input"
                      id={`rowcheck${file.file}`}
                      onChange={(e) => onItemCheck(e, file)}
                    />
                  </td>
                  <td className="small">{renderFileName(file.file)}</td>
                  <td className="small">
                    {(file.size_byte * 0.001).toFixed(2)} (kb)
                  </td>
                  <td className="small">{file.extension}</td>
                  <td className="small">
                    {new Date(file.uploadedDate).toLocaleString()}
                  </td>
                  <td>
                    {["png", "jpeg", "jpg", "svg", "gif"].includes(
                      file.extension
                    ) ? (
                      <img
                        loading="lazy"
                        src={decodeURI(file.cdnPath)}
                        alt={decodeURI(file.file)}
                        className={"bg-white border p-1 list-file-logo"}
                        width={40}
                        height={40}
                      />
                    ) : (
                      renderFileThumbnail(file.extension)
                    )}
                  </td>
                  <td>
                    <ButtonGroup>
                      <a
                        href={file.cdnPath}
                        className="btn btn-success btn-sm"
                        target={"_blank"}
                        rel="noreferrer"
                        title="view"
                      >
                        <i className="bi bi-eye"></i>
                      </a>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() =>
                          handleCopyCdnPath(decodeURI(file.cdnPath))
                        }
                        title="copy to clipboard"
                      >
                        <i className="bi bi-clipboard"></i>
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
          })
        ) : (
          <tr>
            <td colSpan={8} align="center">
              No files
            </td>
          </tr>
        )}
      </>
    );

  return (
    <>
      <Helmet>
        <title>1CDN - Uploaded files</title>
        <meta name="description" content="1CDN - Uploaded files" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/files`}
        />
      </Helmet>
      <div className="dl-shadow-box p-0" data-tour="step-5">
        {/* Filter area */}
        <div className="d-flex justify-content-between align-items-center flex-wrap p-3">
          <Button
            variant="danger"
            size="sm"
            className="mb-sm-0 mb-3"
            disabled={state.SelectedList.length <= 0}
            onClick={handleDeleteSelectedRows}
          >
            {DeleteSomeFilesMutation.isLoading ? (
              <Spinner size="sm" animation="grow" />
            ) : (
              "Delete Selected Files"
            )}
          </Button>
          <div className="d-flex align-items-center justify-content-between">
            {/* <Dropdown className="me-2">
              <Dropdown.Toggle variant="light">
                {filterQueries.extension}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {fileFormats?.map((item) => (
                  <Dropdown.Item
                    key={item}
                    onClick={() =>
                      handleFilters({
                        ...filterQueries,
                        from: "1",
                        extension: item,
                      })
                    }
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown className="me-2">
              <Dropdown.Toggle variant="light">
                {filterQueries.number} Files
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {[10, 20, 50].map((item) => (
                  <Dropdown.Item
                    key={item}
                    onClick={() =>
                      handleFilters({
                        ...filterQueries,
                        from: "1",
                        number: item.toString(),
                      })
                    }
                  >
                    {item} Files
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Pagination className="mb-0">
              <Pagination.Prev
                className="main-color"
                onClick={() =>
                  handleChangeOffsetPagination(
                    parseInt(filterQueries.from) -
                      parseInt(filterQueries.number)
                  )
                }
                disabled={parseInt(filterQueries.from) <= 1 || isLoading}
              />
              <Pagination.Next
                className="main-color"
                onClick={() =>
                  handleChangeOffsetPagination(
                    parseInt(filterQueries.from) +
                      parseInt(filterQueries.number)
                  )
                }
                // disabled={res?.data?.element?.length <= parseInt(filterQueries.number) ? true : false || isLoading}
                disabled={
                  !res?.data?.element?.pagination?.next_page_token || isLoading
                }
              />
            </Pagination>
          </div>
        </div>

        <Table hover responsive className="dl-table list-files mb-0" size="sm">
          <thead>
            <tr>
              <th className="ps-2">#</th>
              <th>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={state.MasterChecked}
                  id="mastercheck"
                  onChange={(e) => onMasterCheck(e)}
                />
              </th>
              <th>
                File
                {/* <span className="ms-2 sorter">
                  <i
                    className={`bi bi-arrow-up ${
                      filterQueries.sortby === "name-asc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "name-asc" })
                    }
                  ></i>
                  <i
                    className={`bi bi-arrow-down ${
                      filterQueries.sortby === "name-desc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "name-desc" })
                    }
                  ></i>
                </span> */}
              </th>
              <th>
                Size
                {/* <span className="ms-2 sorter">
                  <i
                    className={`bi bi-arrow-up ${
                      filterQueries.sortby === "size-asc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "size-asc" })
                    }
                  ></i>
                  <i
                    className={`bi bi-arrow-down ${
                      filterQueries.sortby === "size-desc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "size-desc" })
                    }
                  ></i>
                </span> */}
              </th>
              <th>Extension</th>
              <th>
                Uploaded At
                {/* <span className="ms-2 sorter">
                  <i
                    className={`bi bi-arrow-up ${
                      filterQueries.sortby === "date-asc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "date-asc" })
                    }
                  ></i>
                  <i
                    className={`bi bi-arrow-down ${
                      filterQueries.sortby === "date-desc" && "active"
                    }`}
                    onClick={() =>
                      handleFilters({ ...filterQueries, sortby: "date-desc" })
                    }
                  ></i>
                </span> */}
              </th>
              <th>Preview</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>

        {/* <Button variant='danger' size='sm' onClick={handleDeleteAllFIles} disabled={DeleteAllFilesMutation.isLoading || res?.data.element.length <= 0}>
                {DeleteAllFilesMutation.isLoading ? <Spinner size='sm' animation='grow' /> : " Delete All Files"}
            </Button> */}
      </div>
    </>
  );
};

export default FilesList;
