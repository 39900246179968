import { AxiosError } from 'axios';
import React, { Suspense, useState } from 'react';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth'
import HttpService from '../../../services/http';
import LoadingPage from '../../public/LoadingPage';

const ChangeEmailForm = React.lazy(() => import('./profile/ChangeEmailForm'));
const ChangePasswordForm = React.lazy(() => import('./profile/ChangePasswordForm'));
const ProfileAvatar = React.lazy(() => import('./profile/ProfileAvatar'));
const ProfileForm = React.lazy(() => import('./profile/ProfileForm'));
const ProfilePreview = React.lazy(() => import('./profile/ProfilePreview'));

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
};

const Profile = () => {
  const { customer_identification } = useAuth();

  const [changeEmailModalShow, setChangeEmailModalShow] = useState(false)
  const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)
  const [changeProfileModalShow, setChangeProfileModalShow] = useState(false)

  const { isLoading, isError, data: res, refetch } = useQuery('profile', () => HttpService.get(`/${customer_identification}/profile`), {
    onError: (err: AxiosError<any, any>) => {
      toast.error(err.response?.data?.message || err.message)
    }
  })

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
      <Button variant='link' className='p-0'
        ref={ref}
        onClick={e => {
          props.onClick(e);
        }}
      >
        {props.children}
        <i className="bi bi-three-dots-vertical text-dl-blue"></i>
      </Button>
    )
  );

  if (isLoading) return <Spinner animation='grow' />

  if (isError) return <Button variant='secondary' onClick={() => refetch()}>Reload</Button>

  return (
    <div>
      <Helmet>
        <title>1CDN - Profile</title>
        <meta name="description" content="1CDN - Profile" />
        <link rel="canonical" href={`${process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE}/control-panel/profile`} />
      </Helmet>
      <Suspense fallback={<LoadingPage />}>
        <div className='dl-shadow-box'>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <img title="Super User Manager" loading="lazy" src={res?.data?.element?.user_avatar || "https://cdn.1cdn.app/application/CDN/cdn_avatar.svg"} alt="UMD user avatar" className="cp-logo"/>
            <div className="group-of-btns d-md-block d-none">
              <Button variant='outline-success' onClick={() => setChangeEmailModalShow(true)}>Update email</Button>
              <Button variant='outline-success' className='mx-2' onClick={() => setChangePasswordModalShow(true)}>Change password</Button>
              <Button variant='outline-success' onClick={() => setChangeProfileModalShow(true)}>Edit profile</Button>
            </div>
            <div className='d-md-none d-block'>
              <Dropdown>
                <Dropdown.Toggle variant="link" as={CustomToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setChangeEmailModalShow(true)}>Update email</Dropdown.Item>
                  <Dropdown.Item onClick={() => setChangePasswordModalShow(true)}>Change password</Dropdown.Item>
                  <Dropdown.Item onClick={() => setChangeProfileModalShow(true)}>Edit profile</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <ProfilePreview data={res?.data.element} />
        </div>

        {/* Edit profile modal */}
        <Modal show={changeProfileModalShow} onHide={() => setChangeProfileModalShow(false)} size='lg'>
          <Modal.Header closeButton>
            <h1 className='mb-0 fs-4'>Modify your profile</h1>
          </Modal.Header>
          <Modal.Body>
            <ProfileAvatar avatar={res?.data.element.user_avatar} closeRelatedModal={setChangeProfileModalShow} />
            <ProfileForm data={res?.data.element} closeRelatedModal={setChangeProfileModalShow} />
          </Modal.Body>
        </Modal>

        {/* Change email modal */}
        <Modal show={changeEmailModalShow} onHide={() => setChangeEmailModalShow(false)} size='lg'>
          <Modal.Header closeButton>
            <h2 className='mb-0 fs-4'>Modify your email address</h2>
          </Modal.Header>
          <Modal.Body>
            <ChangeEmailForm user_email={res?.data.element.user_email} closeRelatedModal={setChangeEmailModalShow} />
          </Modal.Body>
        </Modal>

        {/* Change password modal */}
        <Modal show={changePasswordModalShow} onHide={() => setChangePasswordModalShow(false)} size='lg'>
          <Modal.Header closeButton>
            <h3 className='mb-0 fs-4'>Modify your password</h3>
          </Modal.Header>
          <Modal.Body>
            <ChangePasswordForm closeRelatedModal={setChangePasswordModalShow} />
          </Modal.Body>
        </Modal>
      </Suspense>
    </div>
  )
}

export default Profile