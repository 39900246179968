import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useMutation, useQuery } from "react-query";
import HttpService from "../../../services/http";
import { AxiosError } from "axios";
import { useTour } from "@reactour/tour";
import { toast } from "react-toastify";
import PlansInModal from "./PlansInModal";
import useUi from "../../../hooks/useUi";
import { EPlans } from "../../../routes/Plans";
import ServiceExpiry from "./ServiceExpiry";

import "./dashboard.css";
import "./dashboard-responsive.css";
import { useWindowSize } from "../../../hooks/useWindowSize";

const Layout = ({
  children,
  setStep,
}: {
  children: React.ReactNode;
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  const [size] = useWindowSize();
  const { customer_identification, setUser, user } = useAuth();
  const { getUiItem, setUiItem } = useUi();

  const [showTourModal, setShowTourModal] = useState<boolean>();
  const [showPlansModal, setshowPlansModal] = useState(false);
  const [error, setError] = useState<any>();
  const [show, setShow] = useState(false);
  const [avatar, setAvatar] = useState(
    "https://cdn.1cdn.app/application/CDN/cdn_avatar.svg"
  );
  const [showLogOutPopUp, setShowLogOutPopUp] = useState(false);

  const { setIsOpen } = useTour();

  const { isError, isSuccess, isLoading, refetch } = useQuery(
    "payment-status",
    () => HttpService.get(`/auth/${customer_identification}/verify-payment`),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);

        if (
          err.response?.status === 402 ||
          err.response?.data?.code === "RSPYKO001"
        ) {
          setError(err);
        }
      },
    }
  );

  const { isSuccess: isProfileSuccess } = useQuery(
    "profile",
    () => HttpService.get(`/${customer_identification}/profile`),
    {
      enabled: isSuccess,
      onSuccess: (payload) => {
        setUser({
          ...user,
          user_permissions: payload.data.element.user_permissions,
          user_top_plan: payload.data.element.user_top_plan,
        });

        if (
          payload?.data.element.user_avatar &&
          payload?.data.element.user_avatar !== ""
        )
          setAvatar(payload?.data.element.user_avatar);
        else
          setAvatar("https://cdn.1cdn.app/application/CDN/cdn_avatar.svg");
      },
      onError: () => {
        setAvatar("https://cdn.1cdn.app/application/CDN/cdn_avatar.svg");
      },
    }
  );

  useQuery(
    "preferences",
    () => HttpService.get(`/${customer_identification}/profile/preferences`),
    {
      onSuccess: ({ data }) => {
        if (data.element.user_data_preferences._cdn_tour) {
          setShowTourModal(true);
          setUiItem("tourFlag", true);
        }
      },
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdatePreferencesMutation = useMutation<
    boolean,
    AxiosError<any, any>,
    any
  >(
    (showTour) =>
      HttpService.patch(`/${customer_identification}/profile/preferences`, {
        _cdn_tour: showTour,
      }),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const startTour = () => {
    setStep(0);
    setIsOpen(true);
  };

  const handleShowTour = () => {
    setShowTourModal(false);
    startTour();
  };

  const handleShowTourLater = () => {
    UpdatePreferencesMutation.mutate(true, {
      onSuccess: () => {
        setShowTourModal(false);
        setUiItem("tourFlag", true);
      },
    });
  };

  const handlePreventShowTour = () => {
    UpdatePreferencesMutation.mutate(false, {
      onSuccess: () => {
        setUiItem("tourFlag", false);
      },
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMobileShowLogout = () => {
    handleClose();

    setShowLogOutPopUp(true);
  };

  let content;

  if (isLoading) content = <Spinner animation="grow" size="sm" />;

  if (isError)
    content = !error ? (
      <Button variant="secondary" onClick={() => refetch()} size="sm">
        Refetch
      </Button>
    ) : (
      <Alert variant="warning">
        Your subscription is ended.
        <Button
          variant="warning"
          size="sm"
          className="ms-2"
          onClick={() => setshowPlansModal(true)}
        >
          Buy a plan
        </Button>
      </Alert>
    );

  if (isSuccess && isProfileSuccess) content = children;

  return (
    <div className="dash-layout" data-tour="step-1">
      <aside className="dl-sidebar d-xl-flex d-none">
        <ul className="dl-sidebar-menu list-unstyled mb-0 p-0">
          <li className="mb-4">
            <NavLink to={""}>
              <img
                src="https://cdn.1cdn.app/application/CDN/22101907575122_cdn_dashboard_white_logo.svg"
                alt="1CDN"
                width={40}
                height={40}
              />
              <b className="ms-2 text-white">1CDN</b>
            </NavLink>
          </li>

          <li>
            <NavLink to={""} end>
              <i className="bi bi-house me-4"></i>
              Home
            </NavLink>
          </li>

          <li>
            <NavLink to={"files"} end data-tour="step-4">
              <i className="bi bi-files me-4"></i>
              My Files
            </NavLink>
          </li>

          <li data-tour="step-2">
            <NavLink to={"upload-center"}>
              <i className="bi bi-upload me-4"></i>
              Upload
            </NavLink>
          </li>

          <li>
            <NavLink to={"profile"} end>
              <i className="bi bi-person me-4"></i>
              Profile
            </NavLink>
          </li>

          {user?.user_permissions?.open_api && (
            <li>
              <NavLink to={"dev-tools"}>
                <i className="bi bi-gear me-4"></i>
                Settings
              </NavLink>
            </li>
          )}

          <li>
            <NavLink to={"messages"} end>
              <i className="bi bi-envelope me-4"></i>
              Messages
            </NavLink>
          </li>

          <li>
            <NavLink to={"billing"} end>
              <i className="bi bi-credit-card me-4"></i>
              Billing
            </NavLink>
          </li>

          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={() => setShowLogOutPopUp(true)}>
              <i className="bi bi-box-arrow-left me-4"></i>
              Log out
            </a>
          </li>
        </ul>
        {user.user_top_plan !== EPlans.CDN_PREMIUM_BUSINESS && (
          <NavLink
            to={"billing"}
            state={{ showPlansModal: true }}
            className="upgrade-plan-btn"
          >
            <i className="bi bi-lightning-fill me-2"></i>
            Upgrade now
          </NavLink>
        )}
      </aside>
      <section className="dl-content">
        <main>
          <Container fluid>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="actions">
                <ul className="list-unstyled p-0 m-0 d-flex align-items-center dl-nav-icons">
                  <li className="d-lg-block d-xl-none me-3">
                    <Button
                      variant="link"
                      className="text-info p-0 fs-4"
                      onClick={handleShow}
                    >
                      <i className="bt bi-list fw-bolder text-success"></i>
                    </Button>
                  </li>
                  {getUiItem("tourFlag") &&
                    getUiItem("tourFlag") === true &&
                    size >= 1200 && (
                      <li>
                        <ButtonGroup>
                          <Button variant="success" onClick={startTour}>
                            <i className="bi bi-flag ms-2"></i>
                            Start tour guide
                          </Button>
                          <Button
                            variant="success"
                            onClick={handlePreventShowTour}
                          >
                            <i className="bi bi-x"></i>
                          </Button>
                        </ButtonGroup>
                      </li>
                    )}
                </ul>
              </div>
              <Link
                to={"profile"}
                className="shadow-sm rounded-circle d-inline-block ms-auto"
              >
                <img
                  loading="lazy"
                  src={avatar}
                  alt="1CDN"
                  className="cp-logo-layout"
                />
              </Link>
            </div>

            {user.is_trial && (
              <ServiceExpiry
                trial_left_days={user.trial_left_days}
                entity_identification={user.entityIdentification}
              />
            )}

            <div className="routes" id="content-area">
              {content}
            </div>
          </Container>
          <div className="extra-footer text-center mt-2">
            <p className="mb-0 small text-black-50">
              © {new Date().getFullYear()} - 1CDN - Made with love by passionate
              teams - version: {process.env.REACT_APP_VERSION}
            </p>
          </div>
        </main>
      </section>

      <Offcanvas show={show} onHide={handleClose} id="mobile-offcanvas">
        <Offcanvas.Header className="justify-content-between px-4">
          <NavLink
            to={""}
            onClick={handleClose}
            className="text-decoration-none d-flex align-items-center"
          >
            <img
              src="https://cdn.1cdn.app/application/CDN/22101907575122_cdn_dashboard_white_logo.svg"
              alt="1CDN"
              width={30}
              height={30}
            />
            <b className="ms-2 text-white fs-5">1CDN</b>
          </NavLink>
          <Button size="sm" onClick={handleClose} variant="link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="#fff"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="dl-sidebar mobile mt-lg-5 mt-0">
          <ul className="dl-sidebar-menu list-unstyled mb-0 p-0">
            <li onClick={handleClose}>
              <NavLink to={""} end>
                <i className="bi bi-house me-4"></i>
                Home
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"files"}>
                <i className="bi bi-files me-4"></i>
                My Files
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"upload-center"}>
                <i className="bi bi-upload me-4"></i>
                Upload
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"profile"} end>
                <i className="bi bi-person me-4"></i>
                Profile
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"dev-tools"}>
                <i className="bi bi-gear me-4"></i>
                Settings
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"messages"} end>
                <i className="bi bi-envelope me-4"></i>
                Messages
              </NavLink>
            </li>

            <li onClick={handleClose}>
              <NavLink to={"billing"} end>
                <i className="bi bi-credit-card me-4"></i>
                Billing
              </NavLink>
            </li>

            <li onClick={handleMobileShowLogout}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a style={{ cursor: "pointer" }}>
                <i className="bi bi-box-arrow-left me-4"></i>
                Log out
              </a>
            </li>
          </ul>
          {user.user_top_plan !== EPlans.CDN_PREMIUM_BUSINESS && (
            <NavLink
              to={"billing"}
              state={{ showPlansModal: true }}
              className="upgrade-plan-btn w-100"
              onClick={handleClose}
            >
              <i className="bi bi-lightning-fill me-2"></i>
              Upgrade now
            </NavLink>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={showLogOutPopUp}
        onHide={() => setShowLogOutPopUp(false)}
        centered
        size="lg"
      >
        <Modal.Body>
          <Row className="g-3 align-items-center">
            <Col
              md={{ span: 6, order: 1 }}
              xs={{ span: 12, order: 2 }}
              className="text-md-start text-center"
            >
              <h2 className="text-dl-blue">Are you sure?</h2>
              <p style={{ color: "#033768" }}>
                You are going to log out of your account.
              </p>
              <div>
                <Link to={"/logout"} className="btn bg-dl-blue text-white me-2">
                  Log Out
                </Link>
                <Button
                  variant="outline-success"
                  onClick={() => setShowLogOutPopUp(false)}
                >
                  Cancel
                </Button>
              </div>
            </Col>
            <Col
              md={{ span: 6, order: 2 }}
              xs={{ span: 12, order: 1 }}
              className="text-center"
            >
              <img
                loading="lazy"
                src={
                  "https://cdn.1cdn.app/application/CDN/cdn_log_out_1.svg"
                }
                className="img-fluid mb-3 w-75"
                alt="logout logo"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPlansModal}
        keyboard={false}
        size="xl"
        onHide={() => setshowPlansModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose your plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlansInModal
            user_identification={customer_identification}
            isInBilling={true}
            hasPremiumPlan={true}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={size >= 1200 && showTourModal}
        keyboard={false}
        onHide={() => setShowTourModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <Col sm={7}>
              <b className="d-block text-success mb-2">Welcome!</b>
              <h4 className="fw-bolder">
                Let’s start with a quick product tour
              </h4>
              <p className="text-black-50">
                We’ll show you how 1CDN dashboard works.
              </p>

              <div className="actions my-3">
                <Button
                  variant="success"
                  className="rounded-pill me-2"
                  onClick={handleShowTour}
                >
                  Get started
                </Button>
                <Button
                  variant="outline-success"
                  className="rounded-pill"
                  onClick={handleShowTourLater}
                  disabled={UpdatePreferencesMutation.isLoading}
                >
                  {UpdatePreferencesMutation.isLoading ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Show me later"
                  )}
                </Button>
              </div>

              <small className="text-muted mb-0">
                *You can find tour guide at the top of the dashboard
              </small>
            </Col>
            <Col sm={5} className="d-sm-block d-none">
              <div className="text-center">
                <img
                  loading="lazy"
                  src="https://cdn.1cdn.app/application/CDN/cdn_deliver_fast.svg"
                  alt="1CDN"
                  className="img-fluid"
                  width={300}
                  height={150}
                  title="1CDN deliver content very fast"
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Layout;
